<template>
  <div>
    <b-icon
      v-if="data_value === 'crud'"
      :id="`check-${data_index}`"
      icon="check-square"
      variant="success"
      v-b-tooltip.v-secondary="crud + current_field"
    ></b-icon>
    <b-icon
      v-if="data_value === 'cru'"
      :id="`question-${data_index}`"
      icon="question-square"
      variant="warning"
      v-b-tooltip.v-secondary="create_read_update + current_field"
    ></b-icon>
    <b-icon
      v-if="data_value === 'cr'"
      :id="`plus-${data_index}`"
      icon="plus-square"
      variant="info"
      v-b-tooltip.v-secondary="create_read + current_field"
    ></b-icon>
    <b-icon
      v-if="data_value === 'ru'"
      :id="`pencil-${data_index}`"
      icon="pencil-square"
      variant="info"
      v-b-tooltip.v-secondary="read_update + current_field"
    ></b-icon>
    <b-icon
      v-if="data_value === 'r'"
      :id="`x-${data_index}`"
      icon="x-square"
      variant="danger"
      v-b-tooltip.v-secondary="read_only + current_field"
    ></b-icon>
  </div>
</template>

<script>
export default {
  name: "CrudIcons",
  props: {
    current_field: {
      type: String,
      default: "",
    },
    data_value: {
      type: String,
      default: "",
    },
    data_index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      read_only: "Sólo está permitida la vista de ",
      read_update: "El usuario puede ver y modificar ",
      create_read: "El usuario puede ver y crear ",
      create_read_update: "El usuario puede ver, crear y modificar ",
      crud: "El usuario puede ver, crear, modificar y eliminar ",
    };
  },
};
</script>

<style>
</style>